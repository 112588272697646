import { Layout, Meta, Section } from "~/ui";

import errorImg from "~/assets/images/about.png";
import useHasMounted from "~/utils/useHasMounted";

export default function PageNotFound(): JSX.Element {
  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return (
      <Layout>
        <Section
          bgColor="creamGradient"
          baseBgColorTop="primary"
          roundedCorners={{ "top-left": true }}
        >
          <>
            <h1 className="text-primary fw-normal mb-2 placeholder-glow">
              <span className="placeholder col-6"></span>
            </h1>
            <h2 className="h5 fw-normal text-success mb-lg-5">
              <i>for</i>{" "}
              <span className="ms-1 fw-bold placeholder-glow">
                {" "}
                <span className="placeholder col-6"></span>
              </span>
            </h2>
            <div className="d-flex flex-wrap gap-3 placeholder-glow">
              <p className="placeholder col-5"></p>
            </div>
          </>
        </Section>
      </Layout>
    );
  }
  return (
    <Layout>
      <Section
        bgColor="creamGradient"
        baseBgColorTop="primary"
        roundedCorners={{ "top-left": true }}
      >
        <div className="row">
          <div className="col-12 col-lg-8 mb-4 mb-lg-0">
            <h1 className="d-flex flex-column align-self-center text-primary mb-4">
              <span className="display-1">Oh no.</span>
              <span>We can't find the page you are looking for.</span>
            </h1>
          </div>
          <div className="col-12 col-lg-4 text-center mb-4 mb-lg-0">
            <img
              src={errorImg}
              alt="Volunteers packing boxes and a volunteer painting a fence"
              className="img-fluid mt-lg-n5"
              width={364}
              height={735}
            />
          </div>
        </div>
      </Section>
    </Layout>
  );
}

export const Head = () => {
  return (
    <Meta
      title="Page Not Found"
      description="The page you are looking for has been moved or doesn't exist."
    />
  );
};
